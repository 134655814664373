<template>
  <div v-if="product" class="flex flex-col gap-y-2 items-start">
    <div v-if="product.priceBefore" class="flex">
      <Money
        class="line-through text-black whitespace-nowrap"
        :class="[isLarge ? 'text-md' : 'text-sm']"
        :value="product.priceBefore"
      />

      <template v-if="product.cartControl.step !== 1">
        {{ $t('perUnit', { step: product.cartControl.step, unit }) }}
      </template>
    </div>
    <div class="flex items-center gap-x-3 bg-black text-white text-sm px-2 py-1">
      <!-- <span v-if="isRangedProduct" class="mr-2">{{ $t('startsFrom') }}</span> -->

      <Money
        class="whitespace-nowrap"
        :class="{
          'font-bold': isBold,
          'text-md': isLarge,
          'text-sm': !isLarge,
        }"
        :value="product.price"
      />
      <template v-if="product.cartControl.step !== 1">
        {{ $t('perUnit', { step: product.cartControl.step, unit }) }}
      </template>

      <span v-if="discount" data-attr="productCard_discount">
        {{ $n(discount, 'percent') }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ProductNodes } from '@robustastudio/e-commerce/dist/runtime';
import { PropType } from 'vue';
import { Unpacked } from '~/types/utils';

function resolvePricePerStep(price: number, step: number) {
  if (price === 0) {
    return null;
  }
  return price * step;
}
const props = defineProps({
  product: {
    type: Object as PropType<
      Pick<Unpacked<ProductNodes>, 'price' | 'priceBefore' | 'cartControl' | 'type' | 'productsVariants'>
    >,
    default: null,
  },
  isBold: {
    type: Boolean,
    default: false,
  },
  isLarge: {
    type: Boolean,
    default: false,
  },
});

// const { product } = useProductContext();
const { t } = useI18n();

const pricePerStep = computed(() =>
  resolvePricePerStep(Number(props.product.price), Number(props.product?.cartControl.step))
);
const beforePricePerStep = computed(() =>
  resolvePricePerStep(props.product.priceBefore || 0, Number(props.product?.cartControl.step))
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isRangedProduct = computed(() => ['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type));

// price attributes
const unit = computed(() => props.product.cartControl.unit || t('piece').toString());

const price = computed(() => {
  if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
    // get the minimum price of all options
    return props.product?.productsVariants?.reduce(
      (accu, option) => (option.price < accu ? option.price : accu),
      Number.MAX_SAFE_INTEGER
    );
  }
  if (pricePerStep.value && pricePerStep.value !== props.product.price) {
    return pricePerStep.value;
  }

  return props.product.price;
});

const priceBefore = computed(() => {
  if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
    // get the minimum price of all options
    return props.product.productsVariants?.reduce((accu, option) => (option?.price < accu?.price ? option : accu), {
      price: Number.MAX_SAFE_INTEGER,
      priceBefore: 0,
    })?.priceBefore;
  }

  if (beforePricePerStep.value && beforePricePerStep.value !== props.product?.priceBefore) {
    return pricePerStep.value;
  }
  return props.product.priceBefore;
});

const discount = computed(() => {
  if (!props.product?.priceBefore) {
    return 0;
  }

  // round to the nearest multiple of 5
  // discount is between 0 and 1
  return (
    (Math.round((((props.product?.priceBefore - props.product?.price) / props.product?.priceBefore) * 100) / 5) * 5) /
    100
  );
});
</script>

<i18n>
{
    "en": {
        "perUnit": "/ {step} {unit}",
        "piece": "KG",
        "startsFrom": "Starts From"
    },
    "ar": {
        "perUnit": "/ {step} {unit}",
        "piece": "KG",
        "startsFrom": "يبدأ من "
    }
}
</i18n>
