<template>
  <div v-if="options.length > 1">
    <button
      v-for="(option, index) in colorOptions.slice(0, 1)"
      :key="index"
      class="w-4 h-4 border-primary-800"
      :class="{
        'border-black': selectedOption && selectedOption.slug === option.slug,
        'border-transparent': !(selectedOption && selectedOption.slug === option.slug),
      }"
      :aria-label="option.color || 'color'"
      @mouseenter="selectedOption = option"
    >
      <div class="w-full h-full" :style="`background-color: ${option.color}`" />
    </button>
    <div v-if="colorOptions.length > 1">+{{ colorOptions.length - 1 }}</div>
  </div>
</template>
<script setup lang="ts">
import { Options } from '@robustastudio/e-commerce/dist/runtime/composables/products/utils';
import { PropType } from 'vue';
import { Unpacked } from '~~/types/utils';

/**
 * Handle only colors for now
 */
const props = defineProps({
  value: {
    type: Object as PropType<Unpacked<Options>>,
    required: false,
    default: () => ({}),
  },
  options: {
    type: Array as PropType<Options>,
    required: false,
    default: () => [],
  },
});

const selectedOption = ref(props.value);

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue && oldValue.slug !== newValue.slug) selectedOption.value = newValue;
  }
);
const emit = defineEmits(['option']);
watch(selectedOption, option => {
  emit('option', option);
});

const colorOptions = computed(() => props.options.filter(option => option.color && isColor(option.color)));
</script>
